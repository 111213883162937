const setToLS = (key, value) => 
    window.localStorage?.setItem(key, JSON.stringify(value));

const getFromLS = (key) => {
    const value = window.localStorage?.getItem(key);
    if (value) return JSON.parse(value);
};

const popularCurrencies = ['EUR', 'USD', 'GBP', 'CAD', 'AUD', 'NZD'];

const currencies = [
    'AED',    'AFN',    
    //'ALL',
    //'AMD',    
    'ANG',    
    //'AOA',    
    //'ARS',
    'AWG',    'AZN',    'BAM',
    'BBD',    'BDT',    'BGN',   
    //'BIF',
    'BMD',    'BND',    'BOB',    'BRL',
    'BSD',    'BWP',    'BZD',
    //'CDF',
    'CHF',    //'CLP',    
    'CNY',
    //'COP',    
    //'CRC',    
    'CVE',    'CZK',
    'DJF',    'DKK',    'DOP',    'DZD',
    'EGP',    'ETB',    'FJD',
    'FKP',    'GEL',    'GIP',
    'GMD',    
    //'GNF',    
    'GTQ',    'GYD',
    'HKD',    'HNL',    'HRK',    'HTG',
    'HUF',    
    //'IDR',    
    'ILS',    'INR',
    'ISK',    'JMD',    'JPY',    'KES',
    'KGS',    
    //'KHR',    
    'KMF',    
    //'KRW',
    'KYD',    'KZT',    
    //'LAK',    
    //'LBP',
    'LKR',    'LRD',    'LSL',    'MAD',
    'MDL',    
    //'MGA',
    'MKD',    
    //'MMK',
    //'MNT',    
    'MOP',    'MUR',
    'MVR',    
    //'MWK',    
    'MXN',    'MYR',
    'MZN',    'NAD',    'NGN',    'NIO',
    'NOK',    'NPR',    'PAB',
    'PEN',    'PGK',    'PHP',    'PKR',
    'PLN',    
    //'PYG',    
    'QAR',    'RON',
    'RSD',    'RUB',    
    //'RWF',    
    'SAR',
    'SBD',    'SCR',    'SEK',    'SGD',
    'SHP',    
    //'SLL',    
    //'SOS',    
    'SRD',
    //'STD',    
    'SZL',    'THB',    'TJS',
    'TOP',    'TRY',    'TTD',    'TWD',
    //'TZS',    
    'UAH',    
    //'UGX',    
    'UYU',
    //'UZS',    
    //'VND',    
    'VUV',    'WST',
    //'XAF',    
    'XCD',    
    //'XOF',    
    'XPF',
    'YER',    'ZAR',    'ZMW',
];

const currencyList = [...new Set([...popularCurrencies, ...currencies])];

export { setToLS, getFromLS, popularCurrencies, currencies, currencyList };
