const getFullLanguageName = (code) => {
    if (code === 'en') return 'English';
    if (code === 'es') return 'Español';
    if (code === 'fr') return 'Français';
    if (code === 'de') return 'Deutsch';
    if (code === 'it') return 'Italiano';
    if (code === 'pt') return 'Português'
}

const getConfigByMerchantCode = (merchantCode) =>{
    const config = merchantConfigs.find(c => c.code === merchantCode);
    return config ? config : { code: (merchantCode) };
};

const merchantConfigs = [
    {
        code:'AMADEUS_GLOBAL', 
        availableLanguages: ["en", "de", "fr", "es"],
    },
    {
        code:'BES_NZ_CLIMATE_FRIENDLY_TRAVELLER', 
        availableLanguages: ["en", "de", "fr", "es", "it", "pt"],
    },
];

export { getFullLanguageName, getConfigByMerchantCode };
