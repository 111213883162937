import React, { useState, useEffect, Suspense } from 'react';
import Modal from "./modal/Modal.js"
import { data as countryData } from "../util/CountryData.js"
import PropTypes from "prop-types";

import "react-app-polyfill/stable";
import {trackEvent} from "../util/ccAnalyticsV2";

const ChooseCountryModal = ({ isShowModal, setShowModal, country, setCountry  }) => {
    const [planCountryData, setPlanCountryData] = useState([]);
    const [searchCountry, setSearchCountry] = useState("");

    useEffect(() => {
        setDefaultCountryList();
    }, []);

    const setDefaultCountryList = () => {
        setPlanCountryData(countryData.countries);
    }

    const handleLocationModalOk = (country) => {
        setCountry(country);
        //window.location.reload();
        setShowModal(false)
        setDefaultCountryList()
        setSearchCountry("")
        trackEvent( 'countryselect-selector',
            {action: 'modal',label: country})
    };

    const handleLocationModalCancel = () => {
        setShowModal(false)
        setDefaultCountryList()
        setSearchCountry("")
    };

    const handleCountrySearchChange = (e) => {
        const { value } = e.target;
        setSearchCountry(value);

        const valUpper = value.toUpperCase();
        const valLower = value.toLowerCase();

        const filteredCountryList = countryData.countries.filter((item) => {
            return item.name.toLowerCase().includes(valLower) || item.code.includes(valUpper)
        })
        filteredCountryList.length === 0 ?
            setPlanCountryData(
                [{
                    "name": "Not found, please try again",
                    "code": ""
                }]
            ) : setPlanCountryData(filteredCountryList)
    }

    const ListOfCountries = planCountryData.sort((a, b) =>
        a.name > b.name ? 1 : -1)
        .map((item) => {
            return <span key={item.code}>
                <a style={COUNTRY_LIST_BUTTON}
                    onClick={() => handleLocationModalOk(item.code.toLowerCase())} >
                    <span style={{ paddingLeft: 20 }}>{item.name}</span>
                </a>
                <div style={DIVIDER_COUNTRY} />
                </span>
        })

    return <>
        <Modal
           small={true} handleClose={() => setShowModal(false)} show={isShowModal}
        >
            <Suspense
              fallback={
                  <div style={{ textAlign: "center", paddingTop: 100 }}>
                      
                  </div>
              }
            >
                <input
                    value={searchCountry}
                    size="large"
                    onChange={handleCountrySearchChange}
                    placeholder="Search"
                    style={INPUT_STYLE}
                />
                <div style={LIST_COUNTRIES}>
                    {ListOfCountries}
                </div>
            </Suspense>

        </Modal>
    </>
}

const INPUT_STYLE = {
    height: "40px",
    padding: 0,
    fontSize: "20px",
    paddingLeft: 10,
    lineHeight: "1.3em",
    width: "100%",
    color: "black",
    "::placeholder": {
        color: "#aab7c4"
    },
    border: "1px solid #D8D8D8",
    outline: 0
};

const LIST_COUNTRIES = {
    height: 250,
    overflowY: "scroll",
    marginTop: 5,
    border: "1px solid #D8D8D8"
};

const COUNTRY_LIST_BUTTON = {
    textAlign: "left",
    height: 40,
    paddingLeft: 15,
    paddingRight: 15
};

const DIVIDER_COUNTRY = {
    borderTop: "1px solid #D8D8D8",
    width: "100%"
};

ChooseCountryModal.propTypes = {
    setCountryCode: PropTypes.func,
    showModal: PropTypes.bool,
    country: PropTypes.string,
    setShowModal: PropTypes.func,
    myLocation: PropTypes.string
};

export default ChooseCountryModal;