import React from "react";
import { getFullLanguageName } from "./countryFormatter";

const LocaleSelector = ({ selectedCurrency, setIsModalOpen, currentLang }) => {
    return (
        <button className="selection-button translate-dynamic" onClick={() => setIsModalOpen(true)}>
            <div className="pr-2 currency-button">
                <img className="mr-1" src="https://res.cloudinary.com/carbonclick/image/upload/v1652793263/Enterprise/Amadeus/Line_ggigfj.svg" alt="logo" />
                <span>{selectedCurrency}</span>
            </div>
            <div className="lang-button">
                <span className="ml-2">{getFullLanguageName(currentLang)}</span>
            </div>
        </button>
    )
}

export default LocaleSelector;