import React, { useEffect, useState } from "react";
import { Suspense } from "react";
import Modal from "../modal/Modal";
import { getConfigByMerchantCode, getFullLanguageName } from "./countryFormatter";
import CurrencySelector from "./CurrencySelector";
import 'url-search-params-polyfill';
import {trackEvent} from "../../util/ccAnalyticsV2";

const LocaleSelectorModal = ({
    isModalOpen,
    currentLang,
    selectedCurrency,
    localSelectedCurrency,
    setLocalSelectedCurrency,
    merchantCode,
    onChangeCurrency,
    setIsModalOpen,
}) => {
    const { availableLanguages } = getConfigByMerchantCode(merchantCode);

    const [localSelectedLanguage, setLocalSelectedLanguage] = useState(currentLang);
    const [languagesList, setLanguagesList] = useState(availableLanguages);

    const params = new URLSearchParams(window.location.search);
    const langFromUrl = params.get("lang");

    const checkIsLangAvailable = (newLang) => {
        return availableLanguages?.find((lang) => lang === newLang);
    }

    useEffect(() => {
        if (langFromUrl && checkIsLangAvailable(langFromUrl)) {
            setLanguagesList(availableLanguages);
        }
    }, [langFromUrl, localSelectedLanguage]);

    useEffect(() => {
        if (langFromUrl && !checkIsLangAvailable(langFromUrl)) {
            languagesList?.push(langFromUrl);
            setLocalSelectedLanguage(langFromUrl);
        }
        
        if (!checkIsLangAvailable(currentLang)) {
            languagesList.push(currentLang);
            setLocalSelectedLanguage(currentLang);
        }

        setLocalSelectedLanguage(currentLang);
    }, [currentLang, langFromUrl]);

    const sendGAEvent = () => {
        trackEvent( 'language-selector',
        {action: 'focus',
            label: 'en'})
    }

    const onChangeLanguage = (newLang) => {
        if (!window['Weglot']) return;
        Weglot.switchTo(newLang);
    }

    const renderLanguageOptions = () => {
        if (languagesList) {
            return [...new Set(languagesList)]?.filter(lang => lang)?.map((el => (
                <option className="no-translate" key={el} value={el}>{getFullLanguageName(el)}</option>
            )))
        }
    }

    return (
        <section className="mb-3 h-100 selection-modal translate-dynamic">
            <Modal small={true} show={isModalOpen}>
                <div>
                    <div className="pb-2 mb-4" style={{ borderBottom: "1px solid #dfdce6" }}>
                        <h5 className="color-primary">Regional settings</h5>
                    </div>

                    <div className="language-container mb-4">
                        <p>Change language</p>
                        <select
                            className="select"
                            onFocus={() => {sendGAEvent()}}
                            onChange={(e) => setLocalSelectedLanguage(e.target.value)}
                            value={localSelectedLanguage}
                            disabled={!window['Weglot']}
                        >
                            {renderLanguageOptions()}
                        </select>
                    </div>

                    <div className="currency-container mb-4">
                        <p>Change currency</p>
                        <Suspense fallback={<div className="pr-4">Loading...</div>}>
                            <CurrencySelector
                                selectedCurrency={selectedCurrency}
                                localSelectedCurrency={localSelectedCurrency}
                                setLocalSelectedCurrency={setLocalSelectedCurrency}
                            />
                        </Suspense>
                    </div>

                    <div className="button-container">
                        <button
                            className="no-color-btn color-black bg-white text-underline"
                            type="button"
                            onClick={() => {
                                setLocalSelectedCurrency(selectedCurrency);
                                setIsModalOpen(false)
                            }}
                        >
                            Cancel
                        </button>
                        <button
                            className="no-color-btn bg-primary"
                            type="button"
                            onClick={() => {
                                onChangeCurrency(localSelectedCurrency);
                                onChangeLanguage(localSelectedLanguage);
                                setIsModalOpen(false);
                            }}
                        >
                            Save
                        </button>
                    </div>

                </div>
            </Modal>
        </section>
    )
}

export default LocaleSelectorModal;
