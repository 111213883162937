import React, { useEffect } from "react";
import FlightCalculator from "../../common/components/offset/FlightCalculatorV2";
import ReactGA from "react-ga";
import { getFlightSuffix } from "../../common/util/unitConverter";
import { currencyList } from "../../common/components/LocaleSelector/currencyConfig";
import Footer from "../components/Footer";

const OffsetPage = ({
  flightsImpact,
  merchantCode,
  merchantCodeFromUrl,
  selectedCurrency,
  onChangeCurrency,
}) => {

    useEffect(() => {
        //trackAdditionalPageView("/offset", ["aviation", "customer"]);
    }, []);

    const carbonOffsetImpactValue = flightsImpact && !flightsImpact.error && Math.round(flightsImpact.carbonOffsetImpact?.value).toLocaleString();
    const passengerDistanceValue = flightsImpact && !flightsImpact.error && Math.round(flightsImpact.passengerDistance?.value).toLocaleString();
    const passengerDistanceUnit = flightsImpact && !flightsImpact.error && getFlightSuffix(flightsImpact.passengerDistance?.unit.toLowerCase());
    const carbonOffsetImpactUnit = flightsImpact && !flightsImpact.error && flightsImpact.carbonOffsetImpact?.unit.toLowerCase();
    const numberOfContributions = flightsImpact && !flightsImpact.error && flightsImpact.numberOfContributions?.value.toLocaleString();

    return (
        <div className="offset-page">
            <section className="header-bg">

                <article className="py-5">
                    <div className="container mobile-p-1">
                    <div className="center title">
                        <h2 className="bold">Be a Climate Friendly Traveller!</h2>
                        <h2>Offset your flight’s carbon footprint.</h2>
                    </div>

                    <section className="py-5 mobile-py-3" id="FlightCalculator">
                        <div className="container mobile-p-0">
                        <div className="center flight-calculator-unset-font" style={{ zIndex: 200 }}>
                            <FlightCalculator
                                merchantCode={merchantCode}
                                transactionMerchantCode={merchantCodeFromUrl}
                                currency={selectedCurrency}
                                onChangeCurrency={onChangeCurrency}
                                currencyList={currencyList}
                            />
                        </div>
                        </div>
                    </section>
                    </div>
                </article>

            </section>

            <section className="py-5">
                <div className="container py-2 text">
                <div className="flex-column mb-4 text-center">
                    <h3 className="bold">Join the Climate Movement</h3>
                    <p style={{ maxWidth: "500px" }}>
                    Join the community of Climate Friendly Travellers and help
                    increase the positive impact we are having on the planet. Together
                    we are already making a real difference.
                    </p>
                </div>

                <div className="steps">
                    <div className="step">
                    <img
                        src="https://res.cloudinary.com/carbonclick/image/upload/v1653999240/Enterprise/Climate%20Friendly%20Traveller/co2_lhfmd8.svg"
                        alt="plane icon"
                    />
                    <div className="my-3">
                        <h2 className="font-bold ammount color-primary">
                        {passengerDistanceValue}
                        </h2>
                        <p>{passengerDistanceUnit} of Flying Offset</p>
                    </div>
                    </div>

                    <div className="step">
                    <img
                        src="https://res.cloudinary.com/carbonclick/image/upload/v1653999240/Enterprise/Climate%20Friendly%20Traveller/co2_lhfmd8.svg"
                        alt="plane icon"
                    />
                    <div className="my-3">
                        <h2 className="font-bold ammount color-primary">
                        {carbonOffsetImpactValue}
                        </h2>
                        <p>{carbonOffsetImpactUnit} of Carbon Emissions Offset</p>
                    </div>
                    </div>

                    <div className="step">
                    <img
                        src="https://res.cloudinary.com/carbonclick/image/upload/v1653999240/Enterprise/Climate%20Friendly%20Traveller/hand_ayfclo.svg"
                        alt="plane icon"
                    />
                    <div className="mt-3">
                        <h2 className="font-bold ammount color-primary">
                        {numberOfContributions}
                        </h2>
                        <p>Individual Contributions</p>
                    </div>
                    </div>
                </div>
                </div>
            </section>

            <section className="py-5">
                <div className="container">
                <h3 className="bold">Support the World’s Best Climate Projects</h3>

                <div className="d-flex support-section" style={{ justifyContent: 'space-between' }}>
                    <div>
                    <p style={{ maxWidth: "400px" }} className="pb-3">
                        We source the world’s best climate projects that deliver the
                        best impact for people and our planet, and we make sure it’s
                        completely transparent. Learn more about our projects here.
                    </p>
                    <div className="logos">
                        <img height="32px" src="https://res.cloudinary.com/carbonclick/image/upload/f_png,w_950/v1653999308/Enterprise/Climate%20Friendly%20Traveller/vcs-logo_1_czxbib.svg" alt="plane icon" />
                        <img height="16px" src="https://res.cloudinary.com/carbonclick/image/upload/f_png,w_950/v1653999306/Enterprise/Climate%20Friendly%20Traveller/the_gold_standard_logo_xtwjri.svg" alt="plane icon" />
                        <img height="32px" src="https://res.cloudinary.com/carbonclick/image/upload/f_png,w_950/v1653999306/Enterprise/Climate%20Friendly%20Traveller/carbon-registry_logo_u7o7qm.svg" alt="plane icon" />
                    </div>
                    </div>

                    <div className="img-container">
                    <img src="https://res.cloudinary.com/carbonclick/image/upload/v1653999278/Enterprise/Climate%20Friendly%20Traveller/Ellipse_57_g6yjyf.png" alt="plane icon" />
                    <img src="https://res.cloudinary.com/carbonclick/image/upload/v1653999278/Enterprise/Climate%20Friendly%20Traveller/Ellipse_58_ri8sap.png" alt="plane icon" />
                    <img src="https://res.cloudinary.com/carbonclick/image/upload/v1653999278/Enterprise/Climate%20Friendly%20Traveller/Ellipse_59_ubc7gx.png" alt="plane icon" />
                    </div>
                </div>

                </div>
            </section>

            <Footer />
        </div>
    );
};

export default OffsetPage;
