import React from 'react';
import logo from '../media/cc_logo_white.svg';
import facebook from "../media/facebook.svg";
import instagram from "../media/instagram.svg";
import twitter from "../media/twitter.svg";
import { footerLinks } from '../util/uiConfigs/footerConfiguration';

const Footer = ({ showLoginToMyCC}) => {
    return <>
        <footer className="w-100 img-cover footer-img py-6" >
            <div className="container color-white">
                <p><b>Powered by</b></p>
                <div className="d-flex">
                    <div>
                        <a href={footerLinks?.carbonclick} target="_blank" rel="noopener noreferrer">
                            <img style={{ height: 30 }} className="footer-logo" src={logo} alt="carbonclick" />
                        </a>
                    </div>
                    <div className="right">
                        {showLoginToMyCC ? 
                        <>
                                <div className="mobile-hide color-white"><span>Log in to <a className href={"https://my.carbon.click"} target="_blank" rel="noopener noreferrer"><b style={{ textDecoration: "underline" }} className="color-white">My CarbonClick</b></a> to see your progress</span> </div>
                        </>
                        : <div>
                            <a href={footerLinks?.facebook} target="_blank" rel="noopener noreferrer">
                                <img style={{ width: 30 }} src={facebook} alt="carbonclick" />
                            </a>
                            <a href={footerLinks?.instagram} target="_blank" rel="noopener noreferrer">
                                <img style={{ width: 30 }} src={instagram} alt="carbonclick" />
                            </a>
                            <a href={footerLinks?.twitter} target="_blank" rel="noopener noreferrer">
                                <img style={{ width: 30 }} src={twitter} alt="carbonclick" />
                            </a>
                        </div>}
                    </div>
                </div>
                <hr className="mb-4" />

                {showLoginToMyCC && <div className="mobile-show mb-3 color-white"><span>Log in to <a className href={footerLinks?.logInToMyCC} target="_blank" rel="noopener noreferrer"><span style={{ textDecoration: "underline" }} className="color-white">My CarbonClick</span></a> to see the impact</span> </div>}

                <div className="row">
                    <div className="col-4">
                        <div>
                            <div className="mb-3"><a className="color-white" href={footerLinks?.howItWorks} target="_blank" rel="noopener noreferrer">How it works</a></div>
                            <div className="mb-3"><a className="color-white" href={footerLinks?.aboutCarbonClick} target="_blank" rel="noopener noreferrer">About CarbonClick</a></div>
                        </div>
                    </div>
                    <div className="col-4">
                        <div>
                            <div className="mb-3"><a className="color-white" href={footerLinks?.termsAndConditions} target="_blank" rel="noopener noreferrer">Terms &amp; Conditions</a></div>
                            <div className="mb-3"><a className="color-white" href={footerLinks?.privacyPolicy} target="_blank" rel="noopener noreferrer">Privacy policy</a></div>
                        </div>
                    </div>
                    <div className="col-4">
                        <div>
                            <div className="mb-3"><a className="color-white" href={footerLinks?.faq} target="_blank" rel="noopener noreferrer">FAQs</a></div>
                            <div className="mb-3"><a className="color-white" href="mailto:support@carbonclick.com" target="_blank" rel="noopener noreferrer">support@carbonclick.com</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </>
}

export default Footer;