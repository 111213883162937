import React from "react";
import { useLocation } from "react-router-dom";
import LocaleSelector from "../../common/components/LocaleSelector/LocaleSelector";
import LocaleSelectorModal from "../../common/components/LocaleSelector/LocaleSelectorModal";
import ccIcon from "../../common/media/cc_logo_color.svg";

const Header = ({
    selectedCurrency,
    currentLang,
    setIsModalOpen,
    isModalOpen,
    setLocalSelectedCurrency,
    onChangeCurrency,
    localSelectedCurrency,
    merchantCode,
}) => {

    const location = useLocation();
    const isShowHeaderAdd = location.pathname.includes(`/offset`);
  
    return (
        <article className="navigation-container">
            <nav className="navigation p-3 mobile-p-2">
                <div className="nav-info">
                    <img
                        className="carbon-logo"
                        height="35px"
                        src={ccIcon}
                        alt="carbon click logo"
                    />
                    {isShowHeaderAdd && <span className="ml-3">Traveller</span>}
                </div>

                <div className="navigation-menu">
                    <ul className="py-4">
                    <li className="h-center">
                        <LocaleSelector
                            selectedCurrency={selectedCurrency}
                            currentLang={currentLang}
                            setIsModalOpen={setIsModalOpen}
                        />
                    </li>
                    </ul>
                </div>
            </nav>

            <LocaleSelectorModal
                isModalOpen={isModalOpen}
                currentLang={currentLang}
                onChangeCurrency={onChangeCurrency}
                selectedCurrency={selectedCurrency}
                setLocalSelectedCurrency={setLocalSelectedCurrency}
                localSelectedCurrency={localSelectedCurrency}
                merchantCode={merchantCode}
                setIsModalOpen={setIsModalOpen}
            />

        </article>
    )
}

export default Header;
