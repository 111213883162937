import React, { useState } from "react";
import { popularCurrencies, currencies, currencyList } from "./currencyConfig";

const CurrencySelector = ({
    selectedCurrency,
    localSelectedCurrency,
    setLocalSelectedCurrency,
}) => {
    const [isActiveSelect, setIsActiveSelect] = useState(false);

    const checkCurrencyLocal = (c) => {
        return currencyList ? currencyList?.includes(c) : true;
    };

    return (
        <>
            {selectedCurrency && (
                <select
                    className="select"
                    size="large"
                    onChange={(e) => setLocalSelectedCurrency(e.target.value)}
                    value={localSelectedCurrency}
                    onClick={() => setIsActiveSelect(!isActiveSelect)}
                >
                    <optgroup label="popular" className="most-popular">
                        {popularCurrencies?.map(currency => (
                            <option
                                className={'no-translate'}
                                style={{ display: checkCurrencyLocal(currency) ? "block" : "none" }}
                                key={currency}
                                value={currency}
                            >
                                {currency}
                            </option>
                        ))}
                    </optgroup>

                    <optgroup label="other" className="other">
                        {currencies?.map(currency => (
                            <option
                                className={'no-translate'}
                                style={{ display: checkCurrencyLocal(currency) ? "block" : "none" }}
                                key={currency}
                                value={currency}
                            >
                                {currency}
                            </option>
                        ))}
                    </optgroup>
                </select>
            )}
        </>
    )
}

export default CurrencySelector;