import React from "react";
import logo from '../../common/media/cc_logo_white.svg';
import facebook from "../../common/media/facebook.svg"
import instagram from "../../common/media/instagram.svg";
import twitter from "../../common/media/twitter.svg";
import { footerLinks } from "../../common/util/uiConfigs/footerConfiguration";

const Footer = () => {
    return (
        <footer className="w-100 img-cover footer-img" >
            <div className="container color-white py-5">
                <div className="d-flex pb-2 footer-top" style={{ alignItems: 'center' }}>
                    <a href={footerLinks?.carbonclick} target="_blank" rel="noopener noreferrer">
                        <img style={{ height: 30 }} className="footer-logo" src={logo} alt="carbonclick" />
                    </a>
                    <div className="right">
                        <p>Previously offset a flight?</p>
                        <div className="mobile-hide color-white"><span>Log in to <a href={footerLinks?.myCarbonClick} target="_blank" rel="noopener noreferrer"><b style={{ textDecoration: "underline" }} className="color-white">My CarbonClick</b></a> to see your progress</span></div>
                    </div>
                </div>
                <hr className="mb-4" />

                <div className="row" style={{ justifyContent: 'space-between' }}>
                    <div className="col-3">
                        <div className="mb-3"><a className="color-white" href={footerLinks?.aboutCarbonClick} target="_blank" rel="noopener noreferrer">About CarbonClick</a></div>
                        <div className="mb-3"><a className="color-white" href={footerLinks?.faq} target="_blank" rel="noopener noreferrer">FAQs</a></div>
                    </div>
                    <div className="col-3">
                        <div className="mb-3"><a className="color-white" href={footerLinks?.termsAndConditions} target="_blank" rel="noopener noreferrer">Terms &amp; Conditions</a></div>
                        <div className="mb-3"><a className="color-white" href={footerLinks?.privacyPolicy} target="_blank" rel="noopener noreferrer">Privacy policy</a></div>
                    </div>
                    <div className="col-3">
                        <div className="mb-3"><a className="color-white" href={footerLinks?.contactUs} target="_blank" rel="noopener noreferrer">Contact us</a></div>
                        <div className="mb-3"><a className="color-white no-translate" href={"mailto:support@carbonclick.com"} target="_blank" rel="noopener noreferrer">support@carbonclick.com</a></div>

                        <div className="social-media">
                            <a href={footerLinks?.facebook} target="_blank" rel="noopener noreferrer">
                                <img style={{ width: 30 }} src={facebook} alt="carbonclick" />
                            </a>
                            <a href={footerLinks?.instagram} target="_blank" rel="noopener noreferrer">
                                <img style={{ width: 30 }} src={instagram} alt="carbonclick" />
                            </a>
                            <a href={footerLinks?.twitter} target="_blank" rel="noopener noreferrer">
                                <img style={{ width: 30 }} src={twitter} alt="carbonclick" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;