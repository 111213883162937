import React, {useCallback, useEffect, useState} from 'react';
import { Route, Switch } from "react-router-dom";
import '../common/css/global.css';
import './climate-friendly-traveller.css';
import { flights as flightsImpactApi } from '../common/api/impact';
import { projects as getProjects } from '../common/api/projects';
import ClimateFriendlyTravellerPage from './ClimateFriendlyTravellerPage';
import OffsetPage from './pages/OffsetPage';
import { retryWeglotInitializing } from '../common/util/WeglotInitializeUtil';
import { getFromLS, setToLS } from '../common/components/LocaleSelector/currencyConfig';
import Header from './components/Header';
import { currencyList } from "../common/components/LocaleSelector/currencyConfig";
import 'url-search-params-polyfill';

function App() {
  const merchantCode = 'BES_NZ_CLIMATE_FRIENDLY_TRAVELLER';
  const [projects, setProjects] = useState();
  const [flightsImpact, setFlightsImpact] = useState();
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [currentLang, setCurrentLang] = useState(window.localStorage?.getItem("wglang"));
  const [localSelectedCurrency, setLocalSelectedCurrency] = useState(selectedCurrency);
  const [langToDisplay, setLangToDisplay] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const params = new URLSearchParams(window.location.search);
  const merchantCodeFromUrl = params.get("merchantCode");

  useEffect(() => {
    flightsImpactApi().then(response => response.json()).then(data => setFlightsImpact(data)).catch(setFlightsImpact);
    getProjects('en_nz','BES_NZ_CLIMATE_FRIENDLY_TRAVELLER')
        .then(response => response.json())
        .then(data => setProjects(data))
        .catch(setProjects);
  }, []);

  useEffect(() => {
    retryWeglotInitializing(50, (lang) => {setCurrentLang(lang)})
  }, []);

  useEffect(() => {
    const currencyFromLC = getFromLS("currency");

    if (currencyFromLC && !selectedCurrency) {
      onChangeCurrency(currencyFromLC);
      setLocalSelectedCurrency(currencyFromLC);
    } else {
      onChangeCurrency(currencyList[0]);
    }
  }, []);

  const onChangeCurrency = useCallback ((currency) => {
    if (currency) {
      setSelectedCurrency(currency);
      setToLS("currency", currency);
    }
  }, []);

  return (
    <section className="climate-friendly-traveller">
      <Header
        selectedCurrency={selectedCurrency}
        langToDisplay={langToDisplay}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        setLocalSelectedCurrency={setLocalSelectedCurrency}
        localSelectedCurrency={localSelectedCurrency}
        onChangeCurrency={onChangeCurrency}
        currentLang={currentLang}
        merchantCode={merchantCode}
      />

      <Switch>
        <Route
          path={`/offset`}
          key="app-etihad-offset"
        >
          <OffsetPage
            flightsImpact={flightsImpact}
            merchantCode={merchantCode}
            merchantCodeFromUrl={merchantCodeFromUrl}
            selectedCurrency={selectedCurrency}
            onChangeCurrency={onChangeCurrency}
          />
        </Route>
        <Route
          path={`/`}
          key="app-climate-friendly-traveller-landing"
        >
          <ClimateFriendlyTravellerPage
            merchantCode={merchantCode}
            merchantCodeFromUrl={merchantCodeFromUrl}
            projects={projects}
            flightsImpact={flightsImpact}
            selectedCurrency={selectedCurrency}
            onChangeCurrency={onChangeCurrency}
            currentLang={currentLang}
          />
        </Route>
      </Switch>
    </section>
  );
}

export default App;
